<template>
  <base-layout-two page-title="Shifts" page-default-back-link="/more" content-class="background-white">
    <section class="padding-x-16 padding-y-16">
      <h1>Shifts</h1>

      <v-form @submit="fetchShifts">
        <ion-grid>
          <ion-row>
            <!-- Start -->
            <ion-col>
              <base-input label-text="Range Start *">
                <v-field name="range_start" v-slot="{ field }" v-model="filter.range_start" :rules="required">
                  <ion-input name="range_start" v-bind="field" placeholder="" type="date"></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="range_start" class="error-message">
              </v-error-message>
            </ion-col>

            <!-- End -->
            <ion-col>
              <base-input label-text="Range End *">
                <v-field name="range_end" v-slot="{ field }" v-model="filter.range_end" :rules="required">
                  <ion-input name="range_end" v-bind="field" placeholder="" type="date"></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="range_end" class="error-message">
              </v-error-message>
            </ion-col>

            <ion-col class="ion-align-self-end">
              <ion-button type="submit" expand="block" class="margin-y-16">
                Search
              </ion-button>
            </ion-col>

            <ion-col class="ion-align-self-end">
              <ion-button fill="outline" expand="block" class="margin-y-16" :href="exportURL" target="_blank">
                Export Range to CSV
              </ion-button>
            </ion-col>

            <ion-col class="ion-align-self-end">
              <ion-button fill="outline" expand="block" class="margin-y-16" router-link="/shifts/create">
                Add Shift
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </v-form>

      <table>
        <thead>
          <tr>
            <th style="">ID</th>
            <th style="min-width: 100px">Name</th>
            <th style="">Date</th>
            <th style="">Type</th>
            <th style="">Lead</th>
            <th style="">Absence</th>
            <th style="">Clock In</th>
            <th style="">Created By</th>
            <th style="">Approved Hours</th>
            <th style="">Approved on</th>
            <th style="">Approved by</th>
            <th style="">Notes</th>
            <th style="">Approve</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in shifts" :key="row.id">
            <!-- ID -->
            <td>
              <p>{{ row.id }}</p>
            </td>
            <!-- Name -->
            <td>
              <p>{{ row.user.last_name }}, {{ row.user.first_name }}</p>
            </td>
            <!-- Date -->
            <td>
              <p>
                {{ formatDate(row.date, "MM/dd/yy (EEEEEE)") }}
              </p>
            </td>
            <!-- Type -->
            <td>
              <p>{{ row.type }}</p>
            </td>
            <!-- Lead -->
            <td>
              <p>{{ row.lead }}</p>
            </td>
            <!-- Absence -->
            <td>
              <p>{{ row.absence }}</p>
            </td>
            <!-- Clocked In -->
            <td>
              <p v-if="row.clockin_datetime">
                {{ formatDate(row.clockin_datetime, "MM/dd/yy 'at' h:mma") }}
              </p>
            </td>
            <!-- Created Automatically -->
            <td>
              <p>{{ (row.created_automatically) ? 'Clock-In' : '' }}</p>
            </td>
            <!-- Approved Hours -->
            <td>
              <ion-input v-model="row.hours" v-if="!row.approved_on" class="hours">
              </ion-input>
              <p v-if="row.approved_on">{{ row.hours }} hours</p>
            </td>
            <!-- Approved On -->
            <td>
              <p v-if="row.approved_on">
                {{ formatDate(row.approved_on, "MM/dd/yy") }}
              </p>
            </td>
            <!-- Approved By -->
            <td>
              <p v-if="row.approved_by_user">
                {{ row.approved_by_user.first_name }}
                {{ row.approved_by_user.last_name }}
              </p>
            </td>

            <td>
              <ion-input v-model="row.general_notes" v-if="!row.approved_on" class="hours">
              </ion-input>
              <p v-if="row.approved_on">{{ row.general_notes }}</p>
            </td>

            <td>
              <ion-button @click="approveShift(row)" :disabled="row.approved_on || !row.hours">
                Approve
              </ion-button>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonButton, IonInput } from "@ionic/vue";
import { formatDate } from "@/util/helpers";
import {
  format,
  formatDuration,
  intervalToDuration,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonInput,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  computed: {
    ...mapGetters(["authUser", "globalProperty"]),

    exportURL() {
      return `${process.env.VUE_APP_API}/shifts/export?range_start=${this.filter.range_start}&range_end=${this.filter.range_end}`;
    },
  },

  data() {
    return {
      filter: {
        range_start: format(startOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"),
        range_end: format(endOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"),
      },
      shifts: [],
      required: yup.string().required(),
      formatDate,
      formatDuration,
      intervalToDuration,
    };
  },

  ionViewWillEnter() {
    this.fetchShifts();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    /**
     * Fetch Shifts
     */
    async fetchShifts() {
      let queryString = "";
      queryString = Object.keys(this.filter)
        .map((key) => key + "=" + this.filter[key])
        .join("&");

      await this.axios
        .get(`${process.env.VUE_APP_API}/shifts?${queryString}`)
        .then((response) => {
          this.shifts = response.data.shifts.sort((a, b) => {
            // Sort by Last Name
            if (a.user.last_name < b.user.last_name) return -1;
            if (a.user.last_name > b.user.last_name) return 1;

            // Sort by First Name
            if (a.user.last_name === b.user.last_name) {
              if (a.user.first_name < b.user.first_name) return -1;
              if (a.user.first_name > b.user.first_name) return 1;
            }

            // Sort by Date
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;

            return 0;
          });
        })
        .catch((error) => { console.log(error) });
    },

    async approveShift(shift) {
      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/shifts/${shift.id}`,
        data: {
          hours: shift.hours,
          approved_on: new Date().toISOString().replace("T", " ").replace("Z", ""),
          approved_by: this.authUser.id,
          general_notes: shift.general_notes,
        },
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);

          // Replace the entry in the shifts array
          if (response.data.shift) {
            let index = this.shifts.indexOf(shift);
            this.shifts[index] = response.data.shift;
          }
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>


<style scoped>
table {
  width: 100%;
}

table thead {
  color: #000;
  background-color: #dee7e5;
  border-color: #dee7e5;
}

th {
  padding: 10px;
  text-align: left;
  border: 1px solid #dee7e5;
}

td {
  text-align: left;
  vertical-align: top;
  padding: 10px;
  border: 1px solid #d2d2d2;
}

.hours {
  border: 1px solid #dee7e5;
  border-radius: 4px;
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 10px;
}
</style>